<template>
  <div class="standard-page">
    <!-- The header buttons -->
    <div class="d-flex justify-space-between mb-4">
      <!-- The link back to the index -->
      <v-btn
        text
        color="primary"
        @click="$router.replace({ name: 'ProfileOverviewHistory' })"
      >
        <v-icon left> arrow_back </v-icon>

        Back
      </v-btn>
    </div>

    <!-- If there's some data -->
    <div
      v-if="response"
      class="mt-6"
    >
      <v-card
        flat
        class="py-6 px-4"
      >
        <!-- Show the title and menu -->
        <div class="d-flex justify-space-between">
          <div>
            <profile-chip
              :platform="response.platform"
              :data="response.influencer_preview"
            />
          </div>

          <!-- Show menu options -->
          <v-menu
            transition="slide-y-transition"
            :close-on-content-click="true"
            offset-y
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="ml-3"
                color="primary"
                v-bind="attrs"
                v-on="on"
                :disabled="isMakingRequest"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <!-- Show the menu options -->
            <v-list width="140" dense>
              <!-- Show the edit button -->
              <v-list-item
                @click="shouldShowEditDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Edit
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the delete button -->
              <v-list-item
                @click="shouldShowDeleteDialog = true"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Delete
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the copy URL button -->
              <v-list-item
                @click="copyReportLink"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Copy URL
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <!-- Show the option to trigger export as XLSX -->
              <v-list-item
                @click="triggerDownload('xlsx')"
                :disabled="isMakingDownloadRequest.xlsx"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Download XLSX
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="d-flex justify-space-between align-end flex-wrap mt-6 mb-4 text-subtitle-2">
          <!-- Show the date -->
          <div>
            {{ formatDate(response.created_at) }}
          </div>

          <div class="d-flex align-center">
            <!-- Show the number of followers -->
            <v-chip
              color="primary"
              small
            >
              <v-icon small left>
                people
              </v-icon>

              {{ nFormatter(response.influencer_preview.followers) }} Followers
            </v-chip>
          </div>
        </div>

        <v-divider class="my-4" />

        <!-- Show the report brief -->
        <div class="my-4 d-flex flex-wrap align-center">
          <span class="text-subtitle-2">
            Date Range:
          </span>
          &nbsp;
          <v-chip
            small
          >
            {{ dayjs(response.start_date).format("ll") }}
          </v-chip>
          &nbsp;-&nbsp;
          <v-chip
            small
          >
            {{ dayjs(response.end_date).format("ll") }}
          </v-chip>
        </div>

        <!-- Show the overview data -->
        <v-card
          class="box-shadow-soft rounded my-6"
        >
          <div
            v-if="response.overview_data && response.overview_data.posts"
            class="stats-container d-flex"
          >
            <!-- The floating chip -->
            <v-chip
              v-if="response.sponsored_overview_data && response.sponsored_overview_data.posts"
              label
              small
              color="primary"
              class="stats-container__chip"
            >
              <v-icon left small>
                select_all
              </v-icon>

              All Posts
            </v-chip>

            <!-- For each stat value -->
            <div
              v-for="(statValue, index) in statValues.all"
              :key="'stat-value-' + index"
              class="stat-item px-4 py-4"
              :style="{ 'width': `calc(100% / ${statValues.all.length})` }"
            >
              <div class="stat-item__title">
                {{ statValue.format ? statValue.format(statValue.value) : statValue.value }}
              </div>
              <div class="stat-item__text d-flex align-center pt-1 primary--text">
                <v-icon color="primary">
                  {{ statValue.icon }}
                </v-icon>
                &nbsp;
                {{ statValue.text }}
                &nbsp;
              </div>
            </div>
          </div>

          <div
            v-if="response.sponsored_overview_data && response.sponsored_overview_data.posts"
            class="stats-container d-flex"
          >
            <!-- The floating chip -->
            <v-chip
              label
              small
              color="primary"
              class="stats-container__chip"
            >
              <v-icon left small>
                paid
              </v-icon>

              Sponsored Posts
            </v-chip>

            <!-- For each stat value -->
            <div
              v-for="(statValue, index) in statValues.sponsored"
              :key="'stat-value-' + index"
              class="stat-item px-4 py-4"
              :style="{ 'width': `calc(100% / ${statValues.sponsored.length})` }"
            >
              <div class="stat-item__title">
                {{ statValue.format ? statValue.format(statValue.value) : statValue.value }}
              </div>
              <div class="stat-item__text d-flex align-center pt-1 primary--text">
                <v-icon color="primary">
                  {{ statValue.icon }}
                </v-icon>
                &nbsp;
                {{ statValue.text }}
                &nbsp;
              </div>
            </div>
          </div>

          <!-- Show a graph for posts over time -->
          <div
            v-if="dayWiseSeries"
            class="pt-6"
          >
            <day-wise-graph
              :series="dayWiseSeries"
            />
          </div>
        </v-card>

        <!-- Show a filter -->
        <div
          v-if="response.sponsored_overview_data && response.sponsored_overview_data.posts > 0"
          class="d-flex justify-end align-center mb-4"
        >
          <span class="mr-4 text-subtitle-2">
            Only Sponsored Posts?
          </span>

          <v-switch
            v-model="onlySponsoredPosts"
            @change="handleSponsoredPostsChange"
            class="ma-0"
            color="primary"
            hide-details
            inset
          ></v-switch>
        </div>

        <!-- Show the report filters -->
        <v-row>
          <v-col
            v-for="post in posts"
            :key="post.id"
            cols="12"
            lg="6"
          >
            <post-item
              :post="post"
              :preview="response.influencer_preview"
            />
          </v-col>
        </v-row>

        <!-- DOM element to be used to track scroll -->
        <div id="intersect-detector" ref="intersectDetector" v-intersect="handleScrollIntersect"></div>
      </v-card>
    </div>

    <!-- Show the delete confirmation dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isMakingRequest"
      max-width="400"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <v-icon dark class="mr-3"> delete </v-icon>

          Are you sure?
        </v-card-title>

        <v-card-text class="pt-4">
          You are about to delete this report. This action cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :loading="isMakingRequest"
            :disabled="isMakingRequest"
            @click="handleDeleteSubmit"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Show the edit dialog -->
    <v-dialog
      v-model="shouldShowEditDialog"
      max-width="400"
    >
      <v-card v-if="response">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Edit this report
          </div>

          <v-btn
            text
            color="buttonPrimaryText"
            class="primary lighten-1"
            @click="copyReportLink"
          >
            <v-icon left>
              link
            </v-icon>
            Copy URL
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-6">
          <v-select
            v-model="form.sharingAccess"
            label="Sharing Access"
            :items="sharingAccessOptions"
            :hint="sharingAccessHint"
            class="mt-3"
            outlined
            dense
          ></v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            color="primary"
            @click="shouldShowEditDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="handleEditSubmit"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import helper functions
import messageEvents from "@/helpers/messageEvents"
import { getClientID } from "@/helpers/clientHelper"

// Import children components
const PostItem = () => import(/* webpackChunkName: "post-item" */ "@/blocks/common/PostItem")
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const DayWiseGraph = () => import(/* webpackChunkName: "crm-day-wise" */ "@/components/crm/DayWise")

// Subscription ID for messageEvents
const subscriptionId = Symbol("ProfileOverview/Report")

// Export the SFC
export default {
  // Name of the component
  name: "ProfileOverviewReport",

  // Register the components
  components: {
    PostItem,
    ProfileChip,
    DayWiseGraph
  },

  // Define local data variables
  data: () => ({
    // The loading state
    isLoading: false,
    // The report data
    response: null,
    // The posts data
    posts: [],
    postsPagination: 0,
    isFetchingPosts: false,
    onlySponsoredPosts: false,

    // Whether or not we're loading
    isMakingRequest: false,
    shouldShowDeleteDialog: false,
    shouldShowEditDialog: false,

    // The form data
    form: {
      sharingAccess: "self"
    },

    // Whether or not is making a download
    isMakingDownloadRequest: {
      pdf: false,
      xlsx: false
    },
    triggerId: {
      pdf: null,
      xlsx: null
    },
    downloadLoaders: {
      pdf: null,
      xlsx: null,
    },

    // The computed daywise series
    dayWiseSeries: null,

    // The computed stat values
    statValues: null
  }),

  // Define computable properties
  computed: {
    /**
     * Compute the sharing access options
     *
     * @returns {Array}
     */
    postsCount() {
      return this.onlySponsoredPosts ? this.response.sponsored_overview_data.posts : this.response.overview_data.posts
    },

    /**
     * Compute the maximum number of pages possible
     *
     * @returns {number}
     */
    maxPagesPossible() {
      return Math.ceil(this.postsCount / 4)
    }
  },

  // Define vuelidate validation rules
  validations: {
    form: {}
  },

  // Define local method functions
  methods: {
    /**
     * Handle the scroll event for the table rows
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
    handleScrollIntersect(entries, observer, isIntersecting) {
      // Stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If there are no posts, return
      if (!this.posts.length) return

      // Otherwise
      this.fetchPosts()
    },

    /**
     * Fetch the posts for the report
     *
     * @returns {void}
     */
    async fetchPosts() {
      // Check if we're already fetching
      if (this.isFetchingPosts) return

      // If we've reached the maximum number of pages
      if (this.postsPagination >= this.maxPagesPossible) return

      // Mark as fetching and show a loader
      const loaderId = Symbol()
      this.isFetchingPosts = true
      this.$store.dispatch("loaders/add", loaderId)

      // Make the network request
      try {
        // Increment the page
        this.postsPagination++

        // Use the axios helper function
        const response = await axios({
          url: `/api/profile-overviews/${this.response.id}/posts?onlySponsored=${this.onlySponsoredPosts}`,
          params: {
            page: this.postsPagination,
          }
        })

        // Set the posts data
        this.posts = [
          ...this.posts,
          ...response.data.data
        ]
      }
      // Catch the error
      catch (error) {
        logger({ error, type: "ProfileOverview/Report/fetchPosts" })
      }
      // Nonetheless
      finally {
        // Mark as not fetching and hide the loader
        this.isFetchingPosts = false
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    /**
     * Handle the change in the sponsored posts switch
     *
     * @returns {void}
     */
    handleSponsoredPostsChange() {
      // Reset the pagination
      this.postsPagination = 0

      // Reset the posts
      this.posts = []

      // Fetch the posts
      this.fetchPosts()
    },

    /**
     * Delete the report
     *
     * @returns {void}
     */
    async handleDeleteSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Make the network request
      try {
        // Use helper function
        await axios({
          url: `/api/profile-overviews/${this.response.id}`,
          method: "DELETE"
        })

        // If successful, redirect to the index
        this.$router.replace({ name: "ProfileOverviewHistory" })
      } catch (error) {
        // Catch an error
        // Log the error
        logger({ type: "ProfileOverview/Delete Error", error })

        // Show a toast
        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      }

      // Hide the loader
      this.$store.dispatch("loaders/remove", loaderId)
      this.isMakingRequest = false

      // Hide the dialog
      this.shouldShowDeleteDialog = false
    },

    /**
     * Handle the edit action
     *
     * @returns {void}
     */
    async handleEditSubmit() {
      // If the request is already being made
      if (this.isMakingRequest) return

      // Close the dialog
      this.shouldShowEditDialog = false
      this.isMakingRequest = true

      // Use vuex store action
      await this.$store.dispatch("profileOverview/updateItem", { ...this.response, ...this.form })

      // Reset the request state
      this.isMakingRequest = false
    },

    /**
     * Handle the request to export this report as PDF
     *
     * @param {String} type
     * @returns {void}
     */
     async triggerDownload(type) {
      // Show a global loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingDownloadRequest[type] = true

      // Make a network request
      try {
        // Set a triggerId
        this.triggerId[type] = String(Date.now())

        // Use helper function
        await axios({
          url: `/api/profile-overviews/${this.response.id}/download/${type}`,
          method: "POST",
          data: {
            clientId: getClientID(),
            triggerId: this.triggerId[type]
          }
        })
      }
      // Catch the error
      catch (error) {
        // Show a message
        this.$store.dispatch("toasts/add", { text: "An error occurred, please try later." })

        // Hide the loader
        this.isMakingDownloadRequest[type] = false
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)

        // Show a message
        this.$store.dispatch("toasts/add", { text: "Please wait while we generate your report..." })
      }
    },

    /**
     * Handle the message event
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the clientId does not match
      if (getClientID() !== event.localData.clientId) {
        // Stop further execution
        return
      }

      // Switch through the cases
      switch (event.key) {
        case "generate-profile-overview-xlsx-started":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a global loader
          this.downloadLoaders.xlsx = Symbol()
          this.$store.dispatch("loaders/add", this.downloadLoaders.xlsx)

          break

        case "generate-profile-overview-xlsx-completed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "Downloading your report..." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break

        case "generate-profile-overview-xlsx-failed":
          // Check if the triggerId does not match
          if (this.triggerId.xlsx !== event.localData.triggerId) {
            // Stop further execution
            return
          }

          // Show a message saying it succeeded
          this.$store.dispatch("toasts/add", { text: "An error occurred, please contact us." })

          // Hide progress
          this.$store.dispatch("loaders/remove", this.downloadLoaders.xlsx)
          this.isMakingDownloadRequest.xlsx = false

          break
      }
    },

    /**
     * Copy the report link
     *
     * @return {void}
     */
    copyReportLink() {
      // Copy the link value
      const route = this.$router.resolve({ name: "ProfileOverviewReport", params: { uuid: this.response.uuid } })
      navigator.clipboard.writeText(`${window.location.origin}${route.href}`)

      // Show a toast message
      this.$store.dispatch("toasts/add", { text: "URL copied to clipboard!" })
    },

    /**
     * Compute series and annotations for the daywise graph
     *
     * @returns {void}
     */
     computeDaywiseData() {
      // If there are no posts
      if (this.response.posts_count === 0) {
        // Stop further execution
        return
      }

      // If we don't have any daywise data
      if (!this.response.daywise_data) {
        // Stop further execution
        return
      }

      // Get the date range for this report
      const startDate = dayjs(this.response.start_date)
      const endDate = dayjs(this.response.end_date)

      // The daywise posts count array
      const dayWisePosts = []
      const sponsoredDayWisePosts = []

      // Go through each day between the start and end date, and add it to the dates array
      for (let date = startDate; date <= endDate; date = dayjs(date).add(1, "day")) {
        // Get total posts count
        const totalPosts = this.response.daywise_data[date.format("YYYY-MM-DD")] || 0
        // Get sponsored posts count
        const sponsoredPosts = this.response.sponsored_daywise_data?.[date.format("YYYY-MM-DD")] || 0

        // Push it to the list
        dayWisePosts.push([
          date.unix() * 1000,
          totalPosts - sponsoredPosts
        ])

        // Push it to the list
        sponsoredDayWisePosts.push([
          date.unix() * 1000,
          sponsoredPosts
        ])
      }

      // Do we have sponsored posts
      const hasSponsoredData = this.response.sponsored_daywise_data !== null

      // Append the values
      this.dayWiseSeries = [
        {
          name: hasSponsoredData ? "Regular Posts" : "Posts",
          condition: true,
          data: dayWisePosts
        }
      ]

      // If there's any sponsored posts
      if (hasSponsoredData) {
        // Push the values
        this.dayWiseSeries.push(
          {
            name: "Sponsored Posts",
            condition: true,
            data: sponsoredDayWisePosts
          }
        )
      }
    },

    /**
     * Compute the stat values
     *
     * @returns {void}
     */
    computeStatValues() {
      // The values to be shown
      const object = {
        all: [],
        sponsored: []
      }

      // The key map
      const keyMap = {
        sponsored_overview_data: "sponsored",
        overview_data: "all"
      }

      // Loop through both kinds of data
      for (const key in keyMap) {
        // Get the data object
        const data = this.response[key]

        // If data does not exist
        if (!data) {
          // Skip this iteration
          continue
        }

        // Get the array of values
        const values = [
          {
            icon: "collections",
            text: "Posts",
            value: data.posts,
            format: nFormatter
          },
          {
            icon: "thumb_up",
            text: "Likes",
            value: data.likes,
            format: nFormatter
          },
          {
            icon: "play_circle",
            text: "Views",
            value: data.views,
            format: nFormatter,
            hideIfZero: true
          },
          {
            icon: "chat_bubble",
            text: "Comments",
            value: data.comments,
            format: nFormatter,
          },
          {
            icon: "send",
            text: "Shares",
            value: data.shares,
            format: nFormatter,
            hideIfZero: true
          },
          {
            icon: "bookmark",
            text: "Saves",
            value: data.saves,
            format: nFormatter,
            hideIfZero: true
          },
          {
            icon: "bar_chart",
            text: "Avg Eng",
            format: numeral.fPercent,
            value: data.engagement_rate * 100,
          },
          {
            icon: "area_chart",
            text: "Eng/Views",
            format: numeral.fPercent,
            value: (data?.engagement_by_views_rate || 0) * 100,
            hideIfZero: true
          }
        ]
        .filter((search) => search.hideIfZero && search.value === 0 ? false : true)

        // Set the values
        object[keyMap[key]] = values
      }

      // Set the stat values
      this.statValues = object
    }
  },

  /**
   * As soon as the data is ready
   *
   * @returns {void}
   */
  async created() {
    // Show the loaders
    const loaderId = Symbol("ProfileOverviewReport")
    this.$store.dispatch("loaders/add", loaderId)
    this.isLoading = true

    // Fetch the report data
    try {
      // Try making the request
      const response = await axios(`/api/profile-overviews/${this.$route.params.uuid}`)

      // Set the report data
      this.response = response.data

      // Set the form values
      this.form.sharingAccess = this.response.sharing_access

      // Register a subscriber for messageEvents
      messageEvents.register({
        id: subscriptionId,
        module: "profile-overview",
        type: "all",
        key: "all",
        validator: (event) => event.module === "profile-overview" && event.localData.modelId === this.response.id,
        callback: this.handleMessageEvent
      })

      // Fetch the posts
      this.fetchPosts()

      // Compute the daywise data
      this.computeDaywiseData()

      // Compute the stat values
      this.computeStatValues()
    } catch (error) {
      // log using the helper function
      logger({ type: "ProfileOverview/created Fetch Report", error })

      this.$store.dispatch("toasts/add", { text: "An error occurred!" })
    } finally {
      // Hide the loaders
      this.isLoading = false
      this.$store.dispatch("loaders/remove", loaderId)
    }
  },

  /**
   * Before this component is about to be removed
   *
   * @returns {void}
   */
   beforeDestroy() {
    // De-register the event listener
    messageEvents.deregister(subscriptionId)
  }
}
</script>

<style lang="stylus" scoped>
.stats-container
  flex-wrap wrap
  border-bottom 2px solid #f4f6f8
  position relative

  &__chip
    position absolute
    top -10px
    left -4px
    z-index 1

  .stat-item
    flex-grow 1
    min-width 140px
    border-right 2px solid #f4f6f8

    &:last-child
      border-right none

    &__title
      font-size 1.5em
      font-weight bold
      line-height 1.5

    &__text
      font-size 0.85em
      font-weight 600

      .v-icon
        font-size 1.4em
</style>
